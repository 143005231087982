import moment from 'moment';

const formatDateTime = (date?: any) => {
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const formatDate = (date?: any) => {
  if (!date || moment(date).format('DD/MM/YYYY') === 'Invalid date') return '';
  return moment(date).format('DD/MM/YYYY');
};

export default formatDateTime;
