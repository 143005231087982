import axiosClient from 'api';
import { baseURL } from 'config';
import { useNotification } from 'hooks';
import { FilterParams } from 'types';

class ProductService {

  getAll({ pageIndex, pageSize, sortBy, searchText }: FilterParams) {
    return axiosClient.get(`${baseURL}/ProductList/SearchRegisterProduct`, {
      params: {
        Keyword: searchText,
        Sorting: sortBy,
        SkipCount: (pageIndex - 1) * pageSize,
        MaxResultCount: pageSize,
      },
    });
  }

  getAllProduct({ pageIndex, pageSize, sortBy, searchText }: FilterParams) {
    return axiosClient.get(`${baseURL}/ProductList/SearchAll`, {
      params: {
        Keyword: searchText,
        Sorting: sortBy,
        SkipCount: (pageIndex - 1) * pageSize,
        MaxResultCount: pageSize,
      },
    });
  }

  getAllProductRegisted({
    pageIndex,
    pageSize,
    sortBy,
    searchText,
  }: FilterParams) {
    return axiosClient.get(`${baseURL}/ProductList/SearchRegisterProductList`, {
      params: {
        Keyword: searchText,
        Sorting: sortBy,
        SkipCount: (pageIndex - 1) * pageSize,
        MaxResultCount: pageSize,
      },
    });
  }

  get(id: number) {
    return axiosClient.get(`${baseURL}/ProductList/GetProductListDetail`, {
      params: {
        productId: id,
      },
    });
  }

  create(payload: { listProductId: number[] }) {
    return axiosClient.post(
      `${baseURL}/ProductList/RegisterProducts`,
      payload.listProductId
    );
  }

  delete(id: number | null) {
    return axiosClient.post(`${baseURL}/ProductList/UnRegisterProduct`, null, {
      params: {
        productId: id,
      },
    });
  }

  changeStatus(id: number | null, status: boolean) {
    return axiosClient.patch(`${baseURL}/product/ChangeStatus/${id}`, null, {
      params: {
        status,
      },
    });
  }

  getFile(filePath: string) {
    return axiosClient.get(`${baseURL}/file/url-file`, {
      params: {
        filePath,
      },
    });
  }

  updateNorm(productListId: number, norm: number, measureId: number) {
    return axiosClient.put(`${baseURL}/ProductList/UpdateNorm`, null, {
      params: {
        productListId,
        norm,
        measureId
      },
    });
  }

  updatePrice(productListId: number, price: number, measureId: number) {
    return axiosClient.put(`${baseURL}/ProductList/UpdatePriceProduct`, null, {
      params: {
        productListId,
        price,
        measureId
      },
    });
  }

  searchHistoryChangePrice(params: any) {
    return axiosClient.get(`${baseURL}/ProductList/SearchHistoryPriceASync`, {
      params: {
        ProductId: Number(params.productId),
        Keyword: params.searchText,
        PageIndex: params.pageIndex,
        PageSize: params.pageSize,
        Offset: params.pageSize,
        From: this.changeITCToUTC(params.From),
        To: this.changeITCToUTC(params.To)
      },
    });
  }
  changeITCToUTC(date: any): any {
    var utcTimeFrom = null;
    if (date) {
      var indochinaTime = new Date(date);
      utcTimeFrom = new Date(indochinaTime.getTime() - (indochinaTime.getTimezoneOffset() * 60000));
      return utcTimeFrom
    }
    return date

  }

  searchLotNumber({ pageIndex, pageSize, sortBy, searchText, productId }: any) {
    return axiosClient.get(`${baseURL}/ProductList/SearchLotNumberSalePonit`, {
      params: {
        ProductId: Number(productId),
        Keyword: searchText,
        PageIndex: pageIndex,
        PageSize: pageSize,
        Offset: pageSize,
      },
    });
  }


  searchHistoryChangePriceHKcare(params: any) {
    return axiosClient.get(`${baseURL}/ProductList/SearchPriceHistory`, {
      params: {
        ProductId: Number(params.productId),
        Keyword: params.searchText,
        PageIndex: params.pageIndex,
        PageSize: params.pageSize,
        Offset: params.pageSize,
        From: this.changeITCToUTC(params.From),
        To: this.changeITCToUTC(params.To),
        ChangeStaus: params.status
      },
    });
  }
  searchHistoryChangePriceHKcareList(params: any) {
    return axiosClient.get(`${baseURL}/ProductList/SearchImportChangePrice`, {
      params: {
        Id: params.productId,
        // Keyword: params.searchText,
        PageIndex: params.pageIndex,
        PageSize: params.pageSize,
        Offset: params.pageSize,
        // From: this.changeITCToUTC(params.From),
        // To: this.changeITCToUTC(params.To),
        // ChangeStaus:params.status
      },
    });
  }

  searchStockQuantityList(params: any) {
    return axiosClient.get(`${baseURL}/ProductList/SearchStockQuantityOrtherTenant`, {
      params: {
        ProductId: Number(params.productId),
        TenantId: params.TenantId,
        PageIndex: params.pageIndex,
        PageSize: params.pageSize,
        Offset: params.pageSize,
      },
    });
  }
  DownloadTemplateImportProductNorm = async () => {
    return axiosClient.get(`${baseURL}/ProductList/DownloadTemplateImportProductNorm`, {
      responseType: 'blob',
    }).then((res: any) => {
      const target = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = target;
      link.setAttribute('download', 'MauImportDinhMuc.xlsx');
      link.click();
      window.URL.revokeObjectURL(target);

    });
  };
  DownloadTemplateImportProductPrice = async () => {
    return axiosClient.get(`${baseURL}/product/DownloadTemplateImportProductPrice`, {
      responseType: 'blob',
    }).then((res: any) => {
      const target = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = target;
      link.setAttribute('download', 'MauImportGiaBan.xlsx');
      link.click();
      window.URL.revokeObjectURL(target);
    });
  };


  ImportFileProductNorm = async (params: any) => {

    const formData = new FormData();
    for (let key in params) {
      if (key === 'file') {
        if (params.file.length > 0) {
          for (let i = 0; i < params.file.length; i++) {
            formData.append('file', params.file[i]);
          }
        }
      } else {
        formData.append(key, params[key]);
      }
    }

    return axiosClient.post<FormData>(`${baseURL}/ProductList/ImportProductNorm`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'json',
    }).then(async (res: any) => {
      const base64Response = await fetch(`data:${res.data.contentType};base64,${res.data.fileContents}`);
      const target = base64Response.url;
      const link = document.createElement('a');
      link.href = target;
      link.setAttribute('download', res.data.fileDownloadName);
      link.click();
      window.URL.revokeObjectURL(target);

    }).catch(async error => {
      const base64Response = await fetch(`data:${error.response.data.contentType};base64,${error.response.data.fileContents}`);
      const target = base64Response.url;
      const link = document.createElement('a');
      link.href = target;
      link.setAttribute('download', error.response.data.fileDownloadName);
      link.click();
      window.URL.revokeObjectURL(target);
      return error.response;
    })
  }
  ImportFileProductPrice = async (params: any) => {
    const formData = new FormData();
    for (let key in params) {
      if (key === 'file') {
        if (params.file.length > 0) {
          for (let i = 0; i < params.file.length; i++) {
            formData.append('file', params.file[i]);
          }
        }
      } else {
        formData.append(key, params[key]);
      }
    }

    return axiosClient.post<FormData>(`${baseURL}/Product/ImportProductPrice`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'json',
    }).then(async (res: any) => {
      const base64Response = await fetch(`data:${res.data.contentType};base64,${res.data.fileContents}`);
      const target = base64Response.url;
      const link = document.createElement('a');
      link.href = target;
      link.setAttribute('download', res.data.fileDownloadName);
      link.click();
      window.URL.revokeObjectURL(target);

    }).catch(async (error) => {
      const base64Response = await fetch(`data:${error.response.data.contentType};base64,${error.response.data.fileContents}`);
      const target = base64Response.url;
      const link = document.createElement('a');
      link.href = target;
      link.setAttribute('download', error.response.data.fileDownloadName);
      link.click();
      window.URL.revokeObjectURL(target);
      return error.response;
    })
  }
  SearchRegisterProduct({
    Keyword,
    Descending,
    Sorting,
    SkipCount,
    MaxResultCount,
  }: any) {
    return axiosClient.get(`${baseURL}/ProductList/SearchRegisterProduct`, {
      params: {
        Keyword: Keyword,
        Descending: Descending,
        SkipCount: SkipCount,
        Sorting: Sorting,
        MaxResultCount: MaxResultCount
      },
    });
  }

}

export default new ProductService();
