import { Box, Stack, TableSortLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import type { TableCellProps } from '@mui/material/TableCell';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import { MouseEventCurrying } from 'types';
import type { Dictionary, Order } from 'types/common';

export interface Cell<T> {
  id: keyof T;
  label: string | React.ReactElement;
  align?: TableCellProps['align'];
  checkbox?: boolean;
  sortable?: boolean;
  render?: (value: any, item: T) => void;
}

export type Cells<T> = Cell<T>[];

interface Props<T> {
  cells: Cells<T>;
  onSort?: (field: keyof T) => void;
  sortDirection?: Order;
  sortBy?: string;
  checkboxSelection?: (value: boolean) => void;
  dataSelected?: any;
  totalData?: number;
}

const TableHeader = <T extends Dictionary>(props: Props<T>) => {
  const {
    cells,
    checkboxSelection,
    dataSelected,
    totalData = 0,
    sortBy,
    sortDirection,
    onSort,
  } = props;

  const onSelectAllRow = (e: any) => {
    if (checkboxSelection) {
      checkboxSelection(e.target.checked);
    }
  };

  const handleOnSort: MouseEventCurrying<HTMLSpanElement, keyof T> =
    (field) => () => {
      onSort && onSort(field);
    };

  return (
    <TableHead sx={{ backgroundColor: '#F4F6F8' }}>
      <TableRow>
        {cells.map((cell, i) => {
          const { label, align, checkbox, id, sortable } = cell;
          return (
            <TableCell
              key={i}
              align={align}
              sx={label ? {} : { padding: '0px' }}
            >
              {sortable ? (
                <Stack flexDirection="row" sx={{ fontSize: '0.875rem' }}>
                  {label}
                  <TableSortLabel
                    active={sortBy === id}
                    direction={
                      sortBy === id && sortDirection ? sortDirection : 'desc'
                    }
                    onClick={handleOnSort(id)}
                    style={{ fontSize: '0.875rem' }}
                  >
                    <Box component="span" sx={visuallyHidden}>
                      {sortDirection === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  </TableSortLabel>
                </Stack>
              ) : checkbox ? (
                <>
                  {dataSelected && dataSelected.length ? (
                    <Box sx={{ marginTop: 1, color: 'green', px: 1 }}>
                      Chọn {dataSelected.length}/{totalData}
                    </Box>
                  ) : (
                    ''
                  )}
                  <Checkbox
                    onChange={onSelectAllRow}
                    checked={dataSelected.length === totalData}
                    indeterminate={
                      dataSelected.length === totalData ||
                      dataSelected.length === 0
                        ? false
                        : true
                    }
                  />
                </>
              ) : (
                <div style={{ fontSize: '0.875rem' }}>{label}</div>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
